import "./App.css";
import Intro from "./Intro/Intro";
import BDay40 from "./bday40/BDay40";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Intro />} />
      <Route path="bday40" element={<BDay40 />} />
    </Routes>
  );
}

export default App;
